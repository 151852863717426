import styles from './styles.module.scss';
const Header = () => {
  return (
    <div className={styles.headerContainer}>
      <h1>Anidraw &lt;3 Nymbryxion</h1>
      {/* <div className={styles.linksContainer}>
        <a href="https://instagram.com/oponn_">haha</a>
        <a href="https://instagram.com/oponn_">hehe</a>
        <a href="https://instagram.com/oponn_">hoho</a>
        <a href="https://instagram.com/oponn_">hihi</a>
      </div> */}
    </div>
  );
};

export default Header;
